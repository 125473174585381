import React from "react";
import { Box } from "@mui/material";
import ourStoryBackground from "../../../assets/ourStory.png";

const OurStoryImage = () => {
  return (
    <Box
      sx={{
        maxWidth: "30.5%",
        ["@media (max-width:800px)"]: {
          maxWidth: "60%",
        },
      }}
    >
      <Box
        component={"img"}
        src={ourStoryBackground}
        sx={{
          width: "100%",
        }}
      />
    </Box>
  );
};

export default OurStoryImage;
