import React from "react";
import { Controller } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import { generateFont } from "../../../themes/defaultTheme";

const OrderFormRow = ({ isTextarea, form, name, label, error }) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "15px",
      }}
    >
      <Box
        sx={{
          fontFamily: "ProximaNovaRegular",
          ...generateFont({
            fontSize: 20,
            lineHeight: 30,
            letterSpacing: 0,
            fontWeight: "bold",
            minClamp: 14,
          }),
          marginRight: "8.7%",
          minWidth: "28%",
          marginTop: "10px",
        }}
      >
        {label}
      </Box>

      <Box
        sx={{
          flex: "1",
        }}
      >
        <Controller
          name={name}
          control={form.control}
          render={({ field }) => (
            <TextField
              fullWidth
              multiline={isTextarea}
              error={Boolean(error)}
              helperText={error?.message}
              rows={isTextarea ? 3 : undefined}
              {...field}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "0",
                },
                "& .MuiInputBase-input": {
                  padding: "9.5px 14px",
                },
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default OrderFormRow;
