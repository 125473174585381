import React from "react";
import { Box } from "@mui/material";
import SectionTitle from "../components/SectionTitle";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { generateFont } from "../themes/defaultTheme";

const Contact = () => {
  return (
    <Box
      sx={{
        background: "#2a263d08",
        padding: "4.6% 0 1.7% 0",
      }}
    >
      <SectionTitle
        sx={{
          marginBottom: "4.3%",
        }}
      >
        Kontakt
      </SectionTitle>

      <Box
        sx={{
          display: "flex",
          gap: "6.6%",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5.8%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <EmailOutlinedIcon
            sx={{
              fontSize: "41px",
            }}
          />

          <Box
            component={"a"}
            href={"mailto:order@amazewithgrace.com"}
            sx={{
              fontFamily: "ProximaNovaRegular",
              ...generateFont({
                fontSize: 22,
                lineHeight: 30,
                letterSpacing: 0,
                fontWeight: "normal",
              }),
              color: "primary.main",
              textDecoration: "none",
              display: "block",
            }}
          >
            order@amazewithgrace.com
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <InstagramIcon
            sx={{
              fontSize: "41px",
            }}
          />

          <Box
            component={"a"}
            href={"https://www.instagram.com/amazewithgrace/"}
            target={"_blank"}
            sx={{
              fontFamily: "ProximaNovaRegular",
              ...generateFont({
                fontSize: 22,
                lineHeight: 30,
                letterSpacing: 0,
                fontWeight: "normal",
              }),
              color: "primary.main",
              textDecoration: "none",
              display: "block",
            }}
          >
            @amazewithgrace.com
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Box
          component={"a"}
          href={"#"}
          sx={{
            font: "normal normal normal 12px/30px ProximaNovaRegular",
            color: "primary.main",
            textDecoration: "none",
            textAlign: "center",
          }}
        >
          GDPR
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
