import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formDefaultValue from "../../../data/formDefaultValue";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import axios from "axios";

const validationSchema = yup.object({
  name: yup.string().required("Povinné"),
  address: yup.string().required("Povinné"),
  phone: yup.string().required("Povinné"),
  email: yup.string().email("Nesprávny formát e-mailu").required("Povinné"),
  items: yup.array().of(
    yup.object().shape({
      color: yup.string().required("Povinné"),
      textColor: yup.string().required("Povinné"),
      text: yup.string().required("Povinné"),
    })
  ),
});

const useOrderForm = () => {
  const resolver = yupResolver(validationSchema);
  const form = useForm({
    resolver,
    defaultValues: formDefaultValue,
  });
  const { fields, replace } = useFieldArray({
    control: form.control,
    name: "items",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formStatus, setFormStatus] = useState();

  const count = form.watch("count");

  useEffect(() => {
    const newItems = Array.from(Array(Number(count))).map((_, index) => {
      const currentItems = form.getValues("items");

      if (currentItems[index]) {
        return currentItems[index];
      }

      return {
        id: uuidv4(),
        color: "",
        textColor: "",
        text: "",
      };
    });

    replace(newItems);
  }, [count]);

  const onSubmit = (data) => {
    setFormStatus();
    setIsLoading(true);

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("address", data.address);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("note", data.note);
    formData.append("items", JSON.stringify(data.items));

    axios
      .post("/sendEmail.php", formData)
      .then(() => {
        setFormStatus("success");
        form.reset();
      })
      .catch(() => {
        setFormStatus("error");
      })
      .finally(() => {
        setIsLoading(false);

        setTimeout(() => {
          const element = document.getElementById("OrderFormStatus");

          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      });
  };

  return {
    form,
    count,
    fields,
    onSubmit,
    isLoading,
    formStatus,
  };
};

export default useOrderForm;
