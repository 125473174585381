import galleryImage1 from "../assets/Cervena ozdoba s bielym napisom.png";
import galleryImage2 from "../assets/Cervena ozdoba s medenym napisom.png";
import galleryImage3 from "../assets/Cervena ozdoba so striebornym napisom.png";
import galleryImage4 from "../assets/Cira ozdoba s ciernym napisom.png";
import galleryImage5 from "../assets/Cira ozdoba so striebornym napisom.png";
import galleryImage6 from "../assets/Cira ozdoba s medenym napisom.png";
import galleryImage7 from "../assets/Cira ozdoba s bielym napisom.png";
import galleryImage8 from "../assets/Cire ozdoba so zlatym napisom.png";
import galleryImage9 from "../assets/Biela ozdoba s medenym napisom.png";
import galleryImage10 from "../assets/Biela ozdoba so zlatym napisom.png";
import galleryImage11 from "../assets/Biela ozdoba so striebornm napisom.png";
import galleryImage12 from "../assets/Champagne ozdoba so zlatym napisom.png";
import galleryImage13 from "../assets/Champagne ozdoba s medenym napisom.png";
import galleryImage14 from "../assets/Champagne ozdoba s bielym  napisom.png";
import galleryImage15 from "../assets/Champaigne ozdoba so striebornym napisom.png";

export default [
  {
    id: "1",
    img: galleryImage1,
    description: "červená ozdoba s bielym nápisom",
  },
  {
    id: "2",
    img: galleryImage2,
    description: "červena ozdoba s medeným nápisom",
  },
  {
    id: "3",
    img: galleryImage3,
    description: "červená ozdoba so strieborným nápisom",
  },
  {
    id: "4",
    img: galleryImage4,
    description: "číra ozdoba s čiernym nápisom",
  },
  {
    id: "5",
    img: galleryImage5,
    description: "číra ozdoba so strieborným nápisom",
  },
  {
    id: "6",
    img: galleryImage6,
    description: "číra ozdoba s medeným nápisom",
  },
  {
    id: "7",
    img: galleryImage7,
    description: "číra ozdoba s bielym nápisom",
  },
  {
    id: "8",
    img: galleryImage8,
    description: "číra ozdoba so zlatým nápisom",
  },
  {
    id: "9",
    img: galleryImage9,
    description: "biela ozdoba s medeným nápisom",
  },
  {
    id: "10",
    img: galleryImage10,
    description: "biela ozdoba so zlatým nápisom",
  },
  {
    id: "11",
    img: galleryImage11,
    description: "biela ozdoba so strieborným nápisom",
  },
  {
    id: "12",
    img: galleryImage12,
    description: "champagne ozdoba so zlatým nápisom",
  },
  {
    id: "13",
    img: galleryImage13,
    description: "champagne ozdoba s medeným nápisom",
  },
  {
    id: "14",
    img: galleryImage14,
    description: "champagne ozdoba so bielym nápisom",
  },
  {
    id: "15",
    img: galleryImage15,
    description: "champagne ozdoba s o strieborným nápisom",
  },
];
