import React from "react";
import { Button } from "@mui/material";

const OrderButton = ({ sx }) => {
  return (
    <Button component={"a"} href={"#OrderForm"} sx={sx}>
      OBJEDNAŤ
    </Button>
  );
};

export default OrderButton;
