import React from "react";
import { Box } from "@mui/material";
import GalleryLayout from "./components/GalleryLayout";
import OrderButton from "../../components/OrderButton";
import SectionTitle from "../../components/SectionTitle";

const Gallery = () => {
  return (
    <Box
      sx={{
        padding: "6.25% 0",
        margin: "0 6.45%",
        textAlign: "center",
        ["@media (max-width:1000px)"]: {
          margin: "0",
        },
      }}
    >
      <SectionTitle>Galéria</SectionTitle>

      <GalleryLayout />

      <OrderButton />
    </Box>
  );
};

export default Gallery;
