import React from "react";
import { Box, TextField, useMediaQuery } from "@mui/material";
import FormSelect from "../../../components/FormSelect";
import ballColors from "../../../data/ballColors";
import ballTextColors from "../../../data/ballTextColors";
import { Controller } from "react-hook-form";
import { generateFont } from "../../../themes/defaultTheme";

const inputProps = {
  sx: {
    font: "normal normal normal 16px/30px ProximaNovaRegular",
    color: "primary.main",
  },
};

const selectStyle = {
  height: "50px",
  "& .MuiInputBase-input.MuiSelect-select": {
    padding: "9.5px 14px",
    minHeight: "unset",
    paddingRight: "32px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "0",
    borderColor: "#DDDDDD",
  },
  "& .MuiSvgIcon-root": {
    color: "secondary.main",
    fontSize: "29px",
    right: "3px",
  },
};

const OrderFormItemRow = ({ index, form, errors }) => {
  const is600Px = useMediaQuery("(max-width:600px)");

  const renderItem = () => {
    if (is600Px) {
      return (
        <Box
          sx={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              fontFamily: "ProximaNovaRegular",
              ...generateFont({
                fontSize: 39,
                lineHeight: 30,
                letterSpacing: 0,
                fontWeight: "bold",
              }),
              color: "primary.main",
              marginRight: "2.9%",
              minWidth: "2.9%",
            }}
          >
            {index + 1}.
          </Box>

          <Box
            sx={{
              flex: "1",
              width: "90%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "12px",
                gap: "12px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1",
                  width: "48%",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "ProximaNovaRegular",
                    ...generateFont({
                      fontSize: 20,
                      lineHeight: 30,
                      letterSpacing: 0,
                      fontWeight: "bold",
                      minClamp: 14,
                    }),
                    color: "primary.main",
                    marginRight: "12px",
                    whiteSpace: "nowrap",
                    minWidth: "70px",
                  }}
                >
                  Farba gule
                </Box>

                <FormSelect
                  name={`items.${index}.color`}
                  control={form.control}
                  data={ballColors}
                  placeholder={"Vyberte farbu gule"}
                  inputProps={inputProps}
                  sx={selectStyle}
                  formControlSx={{
                    flex: "1",
                  }}
                  error={errors?.["color"]}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1",
                  width: "48%",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "ProximaNovaRegular",
                    ...generateFont({
                      fontSize: 20,
                      lineHeight: 30,
                      letterSpacing: 0,
                      fontWeight: "bold",
                      minClamp: 14,
                    }),
                    color: "primary.main",
                    marginRight: "12px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Farba textu
                </Box>

                <FormSelect
                  name={`items.${index}.textColor`}
                  control={form.control}
                  data={ballTextColors}
                  placeholder={"Vyberte farbu textu"}
                  inputProps={inputProps}
                  sx={selectStyle}
                  formControlSx={{
                    flex: "1",
                  }}
                  error={errors?.["textColor"]}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontFamily: "ProximaNovaRegular",
                  ...generateFont({
                    fontSize: 20,
                    lineHeight: 30,
                    letterSpacing: 0,
                    fontWeight: "bold",
                    minClamp: 14,
                  }),
                  color: "primary.main",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                  minWidth: "70px",
                }}
              >
                Váš text
              </Box>

              <Controller
                name={`items.${index}.text`}
                control={form.control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.["text"])}
                    helperText={errors?.["text"]?.message}
                    {...field}
                    sx={{
                      ...selectStyle,
                      marginRight: "0",
                      flex: "1",
                      "& .MuiInputBase-input": {
                        padding: "13.5px 14px",
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: "1",
          ["@media (max-width:800px)"]: {
            flexWrap: "wrap",
          },
        }}
      >
        <Box
          sx={{
            fontFamily: "ProximaNovaRegular",
            ...generateFont({
              fontSize: 39,
              lineHeight: 30,
              letterSpacing: 0,
              fontWeight: "bold",
            }),
            color: "primary.main",
            marginRight: "2.9%",
            minWidth: "2.9%",
          }}
        >
          {index + 1}.
        </Box>

        <Box
          sx={{
            fontFamily: "ProximaNovaRegular",
            ...generateFont({
              fontSize: 20,
              lineHeight: 30,
              letterSpacing: 0,
              fontWeight: "bold",
              minClamp: 14,
            }),
            color: "primary.main",
            marginRight: "1.3%",
            whiteSpace: "nowrap",
          }}
        >
          Farba gule
        </Box>

        <FormSelect
          name={`items.${index}.color`}
          control={form.control}
          data={ballColors}
          placeholder={"Vyberte farbu gule"}
          inputProps={inputProps}
          sx={selectStyle}
          formControlSx={{
            width: "13.6%",
            marginRight: "3.9%",
            ["@media (max-width:800px)"]: {
              width: "16%",
            },
          }}
          error={errors?.["color"]}
        />

        <Box
          sx={{
            fontFamily: "ProximaNovaRegular",
            ...generateFont({
              fontSize: 20,
              lineHeight: 30,
              letterSpacing: 0,
              fontWeight: "bold",
              minClamp: 14,
            }),
            color: "primary.main",
            marginRight: "1.3%",
            whiteSpace: "nowrap",
          }}
        >
          Farba textu
        </Box>

        <FormSelect
          name={`items.${index}.textColor`}
          control={form.control}
          data={ballTextColors}
          placeholder={"Vyberte farbu textu"}
          inputProps={inputProps}
          sx={selectStyle}
          formControlSx={{
            width: "13.6%",
            marginRight: "3.9%",
            ["@media (max-width:800px)"]: {
              width: "16%",
            },
          }}
          error={errors?.["textColor"]}
        />

        <Box
          sx={{
            fontFamily: "ProximaNovaRegular",
            ...generateFont({
              fontSize: 20,
              lineHeight: 30,
              letterSpacing: 0,
              fontWeight: "bold",
              minClamp: 14,
            }),
            color: "primary.main",
            marginRight: "1.3%",
            whiteSpace: "nowrap",
          }}
        >
          Váš text
        </Box>

        <Controller
          name={`items.${index}.text`}
          control={form.control}
          render={({ field }) => (
            <TextField
              error={Boolean(errors?.["text"])}
              helperText={errors?.["text"]?.message}
              {...field}
              sx={{
                ...selectStyle,
                marginRight: "0",
                flex: "1",
                "& .MuiInputBase-input": {
                  padding: "9.5px 14px",
                },
              }}
            />
          )}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "28px 0",
        borderTop: "1px solid #F3F3F3",
        "&:last-child": {
          borderBottom: "1px solid #F3F3F3",
        },
      }}
    >
      {renderItem()}
    </Box>
  );
};

export default OrderFormItemRow;
