import React from "react";
import { Box } from "@mui/material";
import OurStoryImage from "./components/OurStoryImage";
import OurStoryText from "./components/OurStoryText";

const OurStory = () => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        display: "flex",
        gap: "7.3%",
        alignItems: "center",
        justifyContent: "center",
        padding: "12% 0",
        ["@media (max-width:800px)"]: {
          flexDirection: "column",
          textAlign: "center",
        },
      }}
    >
      <OurStoryImage />

      <OurStoryText />
    </Box>
  );
};

export default OurStory;
