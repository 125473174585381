import React from "react";
import FormSelect from "../../../components/FormSelect";
import prices from "../../../data/prices";
import { Box } from "@mui/material";
import { generateFont } from "../../../themes/defaultTheme";

const inputProps = {
  sx: {
    fontFamily: "ProximaNovaRegular",
    ...generateFont({
      fontSize: 39,
      lineHeight: 30,
      letterSpacing: 0,
      fontWeight: "bold",
    }),
    color: "primary.main",
  },
};

const selectStyle = {
  margin: "0 14px",
  "& .MuiInputBase-input.MuiSelect-select": {
    padding: "16.5px 20px",
    minHeight: "unset",
    paddingRight: "32px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "0",
    borderColor: "#DDDDDD",
  },
  "& .MuiSvgIcon-root": {
    color: "secondary.main",
    fontSize: "29px",
    right: "3px",
  },
};

const OrderFormSelector = ({ form, count }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "4.3%",
      }}
    >
      <Box
        component={"span"}
        sx={{
          fontFamily: "ProximaNovaRegular",
          ...generateFont({
            fontSize: 20,
            lineHeight: 30,
            letterSpacing: 0,
            fontWeight: "bold",
            minClamp: 14,
          }),
          color: "primary.main",
        }}
      >
        Zvoľte počet gúľ
      </Box>

      <FormSelect
        name={"count"}
        control={form.control}
        data={Object.keys(prices)}
        inputProps={inputProps}
        sx={selectStyle}
      />

      <Box
        component={"span"}
        sx={{
          fontFamily: "ProximaNovaRegular",
          ...generateFont({
            fontSize: 20,
            lineHeight: 30,
            letterSpacing: 0,
            fontWeight: "bold",
            minClamp: 14,
          }),
          color: "primary.main",
        }}
      >
        ks. Cena&nbsp;
      </Box>

      <Box
        component={"span"}
        sx={{
          fontFamily: "ProximaNovaRegular",
          ...generateFont({
            fontSize: 39,
            lineHeight: 30,
            letterSpacing: 0,
            fontWeight: "bold",
          }),
          color: "primary.main",
        }}
      >
        {prices[count]}&nbsp;€
      </Box>
    </Box>
  );
};

export default OrderFormSelector;
