import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Banner from "./sections/Banner/Banner";
import OurStory from "./sections/OurStory/OurStory";
import Gallery from "./sections/Gallery/Gallery";
import Layout from "./layouts/Layout";
import OrderForm from "./sections/OrderForm/OrderForm";
import Contact from "./sections/Contact";
import { ThemeProvider } from "@mui/material";
import defaultTheme from "./themes/defaultTheme";

const App = () => {
  return (
    <>
      <CssBaseline />

      <ThemeProvider theme={defaultTheme}>
        <Layout>
          <Banner />

          <OurStory />

          <Gallery />

          <OrderForm />

          <Contact />
        </Layout>
      </ThemeProvider>
    </>
  );
};

export default App;
