export default {
  1: 15,
  2: 30,
  3: 45,
  4: 50,
  5: 60,
  6: 70,
  7: 80,
  8: 90,
  9: 100,
  10: 110,
  11: 120,
  12: 130,
  13: 140,
  14: 150,
  15: 160,
  16: 170,
  17: 180,
  18: 190,
  19: 200,
  20: 210,
  21: 220,
  22: 230,
  23: 240,
  24: 250,
  25: 260,
  26: 270,
  27: 280,
  28: 290,
  29: 300,
  30: 310,
};
