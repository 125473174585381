import React from "react";
import { Box } from "@mui/material";
import BannerTitle from "./components/BannerTitle";
import OrderButton from "../../components/OrderButton";
import backgroundImage from "../../assets/background.png";

const Banner = () => {
  return (
    <Box
      sx={{
        width: "100%",
        background: `url('${backgroundImage}')`,
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Box
        component={"img"}
        src={backgroundImage}
        sx={{
          width: "100%",
        }}
      />

      <OrderButton
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px",
        }}
      />

      <BannerTitle />
    </Box>
  );
};

export default Banner;
