import React from "react";
import { Box, Typography } from "@mui/material";

const OurStoryText = () => {
  return (
    <Box
      sx={{
        maxWidth: "560px",
        color: "primary.contrastText",
        padding: "0 12px",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          marginBottom: "20px",
        }}
      >
        Náš príbeh
      </Typography>

      <Typography variant={"body1"}>
        Vianočné ozdoby Amaze with Grace sú vyrobené a personalizované na
        Slovensku. Obdarujte svojich blízkych originálnym darčekom, ktorý každý
        rok zútulní vianočný stromček. Skvelý nápad na darček nielen pre rodinu
        a priateľov, ale aj pre kolegov a obchodných partnerov.
        <br />
        <br />
        Nakombinujte si svoju obľúbenú kombináciu farieb a vymyslite text, či už
        v podobe mena alebo nápisu, ktorý bude visieť na vianočnom stromčeku.
      </Typography>
    </Box>
  );
};

export default OurStoryText;
