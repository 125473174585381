import { v4 as uuidv4 } from "uuid";

export default {
  count: 4,
  items: [
    {
      id: uuidv4(),
      color: "",
      textColor: "",
      text: "",
    },
    {
      id: uuidv4(),
      color: "",
      textColor: "",
      text: "",
    },
    {
      id: uuidv4(),
      color: "",
      textColor: "",
      text: "",
    },
    {
      id: uuidv4(),
      color: "",
      textColor: "",
      text: "",
    },
  ],
  name: "",
  address: "",
  phone: "",
  email: "",
  note: "",
};
