import React from "react";
import { MenuItem, Select, FormControl, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

const FormSelect = ({
  name,
  control,
  data,
  placeholder,
  inputProps,
  sx,
  error,
  formControlSx,
}) => {
  const errorMessage = error?.message;

  return (
    <FormControl error={Boolean(error)} sx={formControlSx}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value || placeholder}
            inputProps={inputProps}
            MenuProps={{
              MenuListProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    fontFamily: "unset",
                    fontSize: "20px",
                  },
                },
              },
            }}
            sx={{
              ...sx,
            }}
          >
            {placeholder && (
              <MenuItem key={placeholder} value={placeholder} disabled>
                {placeholder}
              </MenuItem>
            )}

            {data.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
};

export default FormSelect;
