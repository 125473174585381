import React from "react";
import { Box, Typography } from "@mui/material";

const GalleryItem = ({ img, description, setModal }) => {
  return (
    <Box>
      <Box
        sx={{
          aspectRatio: "265/331",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component={"img"}
          src={img}
          sx={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => setModal(img)}
        />
      </Box>

      <Typography
        variant={"body2"}
        sx={{
          textAlign: "center",
          color: "primary.main",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default GalleryItem;
