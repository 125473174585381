import { createTheme } from "@mui/material/styles";

const defaultWidth = 1920;
const defaultHeight = 1080;

const pxToVw = (px) => (px / defaultWidth) * 100;
const pxToVh = (px) => (px / defaultHeight) * 100;

const generateFont = ({
  fontSize,
  lineHeight,
  fontWeight,
  letterSpacing,
  minClamp,
}) => {
  return {
    fontSize: `calc((${pxToVw(fontSize)}vw + ${pxToVh(fontSize)}vh) / 2)`,
    lineHeight: `calc((${pxToVw(lineHeight)}vw + ${pxToVh(lineHeight)}vh) / 2)`,
    letterSpacing: `calc((${pxToVw(letterSpacing)}vw + ${pxToVh(
      letterSpacing
    )}vh) / 2)`,
    fontWeight: fontWeight,
    "&": {
      fontWeight: fontWeight,
      fontSize: `clamp(${minClamp ?? fontSize / 2}px, calc((${pxToVw(
        fontSize
      )}vw + ${pxToVh(fontSize)}vh) / 2), ${fontSize}px)`,
      lineHeight: `clamp(${minClamp + 5 ?? lineHeight / 2}px, calc((${pxToVw(
        lineHeight
      )}vw + ${pxToVh(lineHeight)}vh) / 2), ${lineHeight}px)`,
      letterSpacing: `clamp(${letterSpacing / 2}px, calc((${pxToVw(
        letterSpacing
      )}vw + ${pxToVh(letterSpacing)}vh) / 2), ${letterSpacing}px)`,
    },
  };
};

export default createTheme({
  palette: {
    primary: {
      main: "#2A263C",
      contrastText: "#fff",
    },
    secondary: {
      main: "#38344D",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Playfair Display",
      ...generateFont({
        fontSize: 100,
        lineHeight: 105,
        letterSpacing: 0,
        fontWeight: "400",
      }),
    },
    h2: {
      fontFamily: "Playfair Display",
      ...generateFont({
        fontSize: 60,
        lineHeight: 100,
        letterSpacing: 0,
        fontWeight: "400",
      }),
    },
    body1: {
      fontFamily: "ProximaNovaRegular",
      ...generateFont({
        fontSize: 21,
        lineHeight: 30,
        letterSpacing: 0,
        fontWeight: "100",
        minClamp: 15,
      }),
    },
    body2: {
      fontFamily: "ProximaNovaRegular",
      ...generateFont({
        fontSize: 18,
        lineHeight: 30,
        letterSpacing: 0,
        fontWeight: "100",
        minClamp: 14,
      }),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: () => ({
          borderRadius: "30px",
          padding: "20px 48px",
          fontFamily: "ProximaNovaRegular",
          ...generateFont({
            fontSize: 18,
            lineHeight: 20,
            letterSpacing: 1.8,
            fontWeight: "bold",
            minClamp: 12,
          }),
        }),
      },
      defaultProps: {
        color: "primary",
        variant: "contained",
        disableElevation: true,
      },
    },
  },
});

export { generateFont };
