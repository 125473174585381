import React from "react";
import { Box, Typography } from "@mui/material";

const BannerTitle = () => {
  return (
    <Typography
      variant={"h1"}
      sx={{
        textAlign: "center",
        textShadow: "0px 3px 6px #00000029",
        position: "absolute",
        top: "5%",
        left: "50%",
        transform: "translate(-50%, 0)",
        whiteSpace: "nowrap",
        ["@media (max-width:1000px)"]: {
          top: "20%",
        },
        ["@media (max-width:600px)"]: {
          top: "40%",
        },
      }}
    >
      <Box
        component={"span"}
        sx={{
          color: "primary.contrastText",
        }}
      >
        Personalizované
      </Box>

      <br />

      <Box
        component={"span"}
        sx={{
          color: "primary.main",
        }}
      >
        vianočné ozdoby
      </Box>
    </Typography>
  );
};

export default BannerTitle;
