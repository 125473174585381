import React from "react";
import { Typography } from "@mui/material";

const SectionTitle = ({ children, sx }) => {
  return (
    <Typography
      variant={"h2"}
      sx={{
        color: "secondary.main",
        textAlign: "center",
        marginBottom: "4%",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default SectionTitle;
